export default {
  cursor: require("./Cursor"),
  header: require("./Header"),
  "projects-mobile-slider": require("./ProjectsMobileSlider"),
  "key-figures": require("./project/KeyFigures"),
  "triple-medias": require("./project/TripleMedias"),
  "site-menu": require("./SiteMenu"),
  "news-letter": require("./NewsLetter"),
  "realizations": require("./Realizations"),
  "youtube-video": require("./project/YoutubeVideo"),
};
