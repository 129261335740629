import gsap from "gsap";
import AbstractComponent from "../AbstractComponent";
import YouTubePlayer from "youtube-player";
  export default class YoutubeVideo extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.player = null;
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.video = this.$el.querySelector('[data-video-id]')
        this.DOM.videoContainer = this.$el.querySelector('.youtube-video')
        this.DOM.cover = this.$el.querySelector('img')
        this.DOM.playButton = this.$el.querySelector('.play-button')
    }
    initEvents(){

        if (this.DOM.video) {
            this.player = YouTubePlayer(this.DOM.video, {
                videoId: this.DOM.video.getAttribute('data-video-id'),
                host: "https://www.youtube-nocookie.com",
                playerVars: {
                    autoplay: 0,
                    controls:true,
                    color: "white",
                    playsinline: 0,
                },
            });
            this.DOM.cover.addEventListener('click',()=>{
                this.player.playVideo()
                gsap.to([this.DOM.cover,this.DOM.playButton],{autoAlpha:0,pointerEvents:"none",duration:1,onComplete:()=>{
                    gsap.to([this.DOM.cover,this.DOM.playButton],{autoAlpha:0,pointerEvents:'none',duration:0.4})
                    gsap.to(this.DOM.videoContainer,{autoAlpha:1,pointerEvents:'all',duration:0.4})
                }})
            })
            this.DOM.playButton.addEventListener('click',()=>{
                this.player.playVideo()
                gsap.to([this.DOM.cover,this.DOM.playButton],{autoAlpha:0,pointerEvents:"none",duration:1,onComplete:()=>{
                    gsap.to([this.DOM.cover,this.DOM.playButton],{autoAlpha:0,pointerEvents:'none',duration:0.4})
                    gsap.to(this.DOM.videoContainer,{autoAlpha:1,pointerEvents:'all',duration:0.4})
                }})
            })
        }

    }
    destroy() { }
}